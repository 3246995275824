.notFound {
    flex: 1 1 0px;
    align-items: center;
    justify-content: center;
    background-color: white;
    gap: var(--avena-spacing-xl);
    padding: var(--avena-spacing-md);
    text-align: center;

    img {
        width: 80%;
        max-width: 538px;
        height: auto;
    }

    p {
        font: var(--avena-font-l-regular);
        color: var(--avena-text-color-secondary);
    }
}
